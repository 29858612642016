const React = require('react');
const { string } = require('prop-types');
const classnames = require('classnames');

const ICON_ID = 'poly_star';
const namespace = 'ui-pdp-icon ui-pdp-icon--star-empty';

const IconStarEmpty = ({ className }) => (
  <svg className={classnames(namespace, className)} width="10" height="10" viewBox="0 0 10 10" fill="none">
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconStarEmpty.propTypes = {
  className: string,
};

IconStarEmpty.defaultProps = {
  className: null,
};

module.exports = React.memo(IconStarEmpty);
exports = module.exports;
exports.ICON_ID = ICON_ID;
