const React = require('react');
const StarHalfIcon = require('../../icons/poly_star_half');
const StarFullIcon = require('../../icons/poly_star_fill');
const StarEmptyIcon = require('../../icons/poly_star');

const getStarsToDraw = (rating_stars, stars) => {
  const REVIEW_MAX_STARS = 5;
  const starsToDraw = rating_stars?.value ? rating_stars.value : stars;

  const full = Math.floor(starsToDraw);
  const half = Math.ceil(starsToDraw - full);
  const empty = REVIEW_MAX_STARS - half - full;

  const ratings = [];
  let key = 0;
  for (let f = 0; f < full; f += 1) {
    ratings.push(<StarFullIcon key={(key += 1)} />);
  }
  for (let h = 0; h < half; h += 1) {
    ratings.push(<StarHalfIcon key={(key += 1)} />);
  }
  for (let e = 0; e < empty; e += 1) {
    ratings.push(<StarEmptyIcon key={(key += 1)} />);
  }

  return ratings;
};

module.exports = { getStarsToDraw };
