const React = require('react');
const { useContext } = require('react');
const { string, shape, number, bool } = require('prop-types');
const classnames = require('classnames');
const StaticPropsContext = require('../../context/static-props');

const Image = require('../../image');
const StyledLabel = require('../../styled-label');
const componentEnhance = require('../../../lib/component-enhance');
const Tooltip = require('../../tooltip');
const { trackEvent } = require('../../../lib/tracking');

const ZINDEX_TOOLTIP = 1080;
const MOBILE = 'mobile';

const Compats = ({ title, action, icon, tooltip, classname }) => {
  const { deviceType } = useContext(StaticPropsContext);
  const { target, track } = action || {};
  const { text, values, color, font_size, font_family } = title;
  const { url } = icon;
  const { andes_tooltip } = tooltip;
  /* istanbul ignore next */
  const onClickHandler = e => {
    e.preventDefault();
    const specs = document.querySelector(`#${target}`);
    if (track) {
      trackEvent(track);
    }
    if (specs) {
      specs.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const [tooltipVisible, setTooltipVisible] = React.useState(andes_tooltip?.state === 'VISIBLE');
  /* istanbul ignore next */
  const tooltipOptions = {
    id: andes_tooltip?.id,
    content: andes_tooltip?.body?.text,
    showCloseButton: andes_tooltip?.closeable,
    visible: tooltipVisible,
    offsetY: deviceType === MOBILE ? 5 : 0,
    storable: andes_tooltip?.storable,
    side: andes_tooltip?.tooltip_location?.toLowerCase(),
    type: andes_tooltip?.tooltip_type?.toLowerCase(),
    trigger: 'no-trigger',
    zIndex: deviceType === MOBILE ? ZINDEX_TOOLTIP : 1,
    onClose: () => setTooltipVisible(false),
  };

  return (
    <div className={classname} data-testid={classname}>
      <Image alt="" src={url.src} className={`${classname}-icon`} />
      <Tooltip {...tooltipOptions} className={`${classname}__tooltip-compats`}>
        <StyledLabel
          className={classnames(`${classname}__text`, {
            [`ui-pdp-color--${color}`]: color,
            [`ui-pdp-size--${font_size}`]: font_size,
            [`ui-pdp-family--${font_family}`]: font_family,
          })}
          onClick={action && onClickHandler}
          key={text}
          as="p"
          text={componentEnhance.jsx(text, values)}
        />
      </Tooltip>
    </div>
  );
};

Compats.propTypes = {
  icon: shape({
    url: shape({
      src: string,
    }),
  }),
  title: shape({
    bg_color: string,
    color: string,
    font_size: string,
    text: string,
    font_family: string,
    values: shape({
      compatibility: shape({
        color: string,
        font_family: string,
        font_size: string,
        text: string,
      }),
      user_selection: shape({
        color: string,
        font_family: string,
        font_size: string,
        text: string,
      }),
    }),
  }),
  action: shape({
    action_type: string,
    duration: number,
    id: string,
    target: string,
    timeout: number,
    track: shape(),
  }),
  tooltip: shape({
    id: string,
    timeout: number,
    duration: number,
    action_type: string,
    andes_tooltip: shape({
      id: string,
      type: string,
      state: string,
      closeable: bool,
      closed: bool,
      storable: bool,
      has_shadow: bool,
      change_color: bool,
      tooltip_style: string,
      body: {
        text: string,
        color: string,
        font_size: string,
        font_family: string,
      },
      tooltip_location: string,
    }),
  }),
  classname: string,
};

Compats.defaultProps = {
  icon: null,
  title: null,
  action: null,
  tooltip: {},
  classname: null,
};

module.exports = Compats;
