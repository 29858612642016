const React = require('react');
const { oneOfType, node, string, shape } = require('prop-types');
const classnames = require('classnames');
const componentEnhance = require('../../../lib/component-enhance');
const StyledLabel = require('../../styled-label');

const namespace = 'ui-pdp-title';

const CompatsTitle = ({ className, title, compats }) => {
  const { text, values, color, font_size, font_family } = compats;

  return (
    <h1 className={classnames(namespace, className)}>
      {title}
      {compats && (
        <StyledLabel
          className={classnames(`${namespace}__text`, {
            [`ui-pdp-color--${color}`]: color,
            [`ui-pdp-size--${font_size}`]: font_size,
            [`ui-pdp-family--${font_family}`]: font_family,
          })}
          key={text}
          font_family={null}
          as="spam"
          text={componentEnhance.jsx(text, values)}
        />
      )}
    </h1>
  );
};

CompatsTitle.propTypes = {
  className: string,
  title: oneOfType([string, node]).isRequired,
  compats: shape({
    bg_color: string,
    color: string,
    font_size: string,
    text: string,
    font_family: string,
    values: shape({
      compatibility: shape({
        color: string,
        font_family: string,
        font_size: string,
        text: string,
      }),
      user_selection: shape({
        color: string,
        font_family: string,
        font_size: string,
        text: string,
      }),
    }),
  }),
};

CompatsTitle.defaultProps = {
  className: '',
};

module.exports = CompatsTitle;
