const React = require('react');
const { shape, string, func } = require('prop-types');
const componentEnhance = require('../../lib/component-enhance');
const StyledLabel = require('../styled-label');
const IconFactory = require('../icons/factory-builder')();

const namespace = 'ui-pdp-seller-validated';

const onClickHandler = (e, onScrollComponent) => onScrollComponent && onScrollComponent({ focus: true });

const SellerValidated = ({ title, icon, onScrollComponent }) => (
  <div className={namespace}>
    <StyledLabel
      onClick={e => onClickHandler(e, onScrollComponent)}
      className={`${namespace}__title`}
      text={componentEnhance.jsx(title.text, title.values)}
      data-testid="seller-validated"
      font_size={title.font_size}
      font_family={title.font_family}
      color={title.color}
    >
      {IconFactory(icon, `${namespace}__icon`)}
    </StyledLabel>
  </div>
);

SellerValidated.propTypes = {
  title: shape({
    text: string.isRequired,
    color: string,
    font_family: string,
    font_size: string,
  }).isRequired,
  icon: shape({
    id: string,
    size: string,
  }).isRequired,
  onScrollComponent: func,
};

SellerValidated.defaultProps = {
  onScrollComponent: null,
};

module.exports = SellerValidated;
