const React = require('react');
const { string } = require('prop-types');
const CompatsHighlightNotes = require('./compats-highlight-card');
const CompatsHighlight = require('./compats-highlight');

const namespace = 'ui-compats-highlight';

const CompatsHighlightWrapper = props => {
  const { type } = props;

  switch (type) {
    case 'highlight_notes':
      return <CompatsHighlightNotes classname={namespace} {...props} />;
    case 'composed_highlight':
      return <CompatsHighlight classname={namespace} {...props} />;
    default:
      return null;
  }
};

CompatsHighlightWrapper.propTypes = {
  type: string,
};

CompatsHighlightWrapper.defaultProps = {
  type: null,
};

module.exports = CompatsHighlightWrapper;
